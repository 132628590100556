<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3">
    <MDBCardBody>
	<MDBContainer fluid class="mt-3">
    <transition name="fade">
      <PageLoading v-if="PageLoading"></PageLoading>
    </transition>
    <MDBRow>
      <MDBCol md="2">
        <MDBInput label="帳號" placeholder="帳號" v-model="where.member.email" />
      </MDBCol>
      <MDBCol md="2">
        <MDBInput :label="$t('COMMON.PROMONAME')" :placeholder="$t('COMMON.PROMONAME')" v-model="where.promo.name" />
      </MDBCol>
      <MDBCol class="mb-2 text-end">
        <MDBBtn color="primary" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn>
      </MDBCol>
    </MDBRow>
		<DataTable
			:header="headers"
			:tabledata="desserts"
			v-model:total="total"
			v-model:options="options"
			v-model:loading="loading"
		>
			<!-- 時間轉換 -->
      <template v-slot:member="{ item }">
        {{ `${item.member.email} / ${item.member.name}` }}
      </template>
      <template v-slot:created_at="{ item }">
        {{ Common.TimeFormat(item.created_at) }}
      </template>
		</DataTable>
	</MDBContainer>
  </MDBCardBody>
  </MDBCard>
</template>

<script>
import { MDBContainer, MDBCard, MDBCardBody, MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import PageLoading from '@/components/Elements/PageLoading';
import { ref, reactive } from "vue";
import { useI18n } from 'vue-i18n';
import qs from 'qs';

export default {
  name: "PromoRecord",
  components: {
		DataTable,
		MDBContainer,
		Breadcrumbs,
    MDBCard,
    MDBCardBody,
    PageLoading,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBBtn,
  },
  setup() {
    const i18n = useI18n();
    const PageLoading = ref(false);
    const loading = ref(true);
    const total = ref(0);
    const Common = CommonService;
    const desserts = ref([]);

    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["created_at"],
      sortDesc: ['desc'],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    });

    const where = reactive({
      promo: {
        name: "",
      },
      member: {
        email: "",
      }
    });

    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no"
      },
      { text: i18n.t("COMMON.MEMBERNAME"), value: "member" },
      { text: i18n.t("COMMON.PROMONAME"), value: "promo.name" },
      { text: i18n.t("COMMON.CREATETIME"), value: "created_at" },
    ]);

    const GetTable = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/PromoRecord?"+qs.stringify(Object.assign(CommonService.SortHandleObject(options),where)),
          {}
        ).then(response => {
          if (response.status == 200) {
            desserts.value = CommonService.DataNoHandle(
              response.data.data.data,
              options.page,
              options.itemsPerPage
            );
            total.value = response.data.data.total;
            resolve(response);
          }
        });
      });
    };

		return {
      PageLoading,
      loading,
      total,
      Common,
      options,
      headers,
      desserts,
      GetTable,
      where,
    };
  },
  watch: {
		options: {
      handler() {
        this.GetTable();
      },
      deep: true
    },
  },
  mounted() {
    this.GetTable();
  },
};
</script>